import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { AuthService } from '@core/services/business/auth/auth.service';
import { LOGIN_URL, LOGIN_WITH_OTP_URL, REFRESH_URL } from '@core/services/api/auth/auth-http.service';
import { InternetConnectionService } from '../services/business/utils/internet-connection.service';
import { EVENT_URL } from '@core/services/api/events/event-http.service';
import { BOOKING_URL } from '@core/services/api/booking/booking-location-http.service';

/**
 * Ключевое слово для хидер пропуска обработки ошибки.
 * ВАЖНО: не менять название данного ключа, оно зашито в CORS сервера
 */
const SKIP_ERROR_INTERCEPTOR_KEYWORD = 'skip-error-interceptor';
export const SKIP_ERROR_INTERCEPTOR_HEADER = new HttpHeaders({ [SKIP_ERROR_INTERCEPTOR_KEYWORD]: '' });

// Урлы, в которых необходимо пропускать обработку ошибки, так как внутри метода есть своя обработка
export const SKIP_ERROR_PROCESSING_URLS: string[] = [
  REFRESH_URL,
  LOGIN_URL,
  LOGIN_WITH_OTP_URL,
  EVENT_URL,
  BOOKING_URL,
];

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private urlsToSkipErrorHandling: string[] = [];

  constructor(
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private internetConnectionService: InternetConnectionService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<unknown>) => {
        this._addSkipErrorInterceptorHeaderIfNeed(event, request);
      }),
      catchError((error: HttpErrorResponse) => {
        const isUrlHasCustomErrorProcessing: boolean = this._skipUrlWithCustomErrorProcessing(request.url);
        const isUrlHasSkipErrorHeader: boolean = this._skipErrorInterceptorHeaderHandler(request);

        if (isUrlHasCustomErrorProcessing || isUrlHasSkipErrorHeader) {
          return throwError(error);
        }

        if (!this.internetConnectionService.isOnline) {
          return of();
        }

        if (error.status >= 500) {
          this.snackBar.open('Сервер недоступен, ведутся работы. Приносим извинения за неудобства', null, {
            duration: 5000,
          });
          return of();
        }

        switch (error.status) {
          case 401:
            // Вызываем функцию по обновлению токена и повторяем запрос
            return this.authService.refreshTokenAndRepeatRequest(request, next);
          case 429:
            // Игнорируем сообщение от сервера о слишком частых запросах
            return of();
          default:
            console.log('Unprocessed error: ', error.status, error.message);
            return throwError(error);
        }
      }),
    );
  }

  /**
   * Для отправляемого запроса: если в списке хидеров есть хидер указывающий на
   * пропуск ошибки, то добавить урл в список исключений
   */
  private _addSkipErrorInterceptorHeaderIfNeed(event: HttpEvent<unknown>, request: HttpRequest<unknown>): void {
    if (event.type === HttpEventType.Sent && request.headers.has(SKIP_ERROR_INTERCEPTOR_KEYWORD)) {
      this.urlsToSkipErrorHandling.push(request.url);
    }
  }

  /**
   * Если в списке урлов есть урл из текущего запроса, то не обрабатывать ошибку
   */
  private _skipErrorInterceptorHeaderHandler(request: HttpRequest<unknown>): boolean {
    const skipInterceptor: boolean = this.urlsToSkipErrorHandling.includes(request.url);
    if (skipInterceptor) {
      this.urlsToSkipErrorHandling = this.urlsToSkipErrorHandling.filter((url) => url !== request.url);
    }
    return skipInterceptor;
  }

  private _skipUrlWithCustomErrorProcessing(requestUrl: string): boolean {
    return !!SKIP_ERROR_PROCESSING_URLS.find((url: string) => requestUrl.includes(url));
  }
}
