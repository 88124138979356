<div
  class="container"
  (click)="clickEvent.emit()"
  (mouseenter)="hoverEvent.emit()"
  [kpTooltip]="tooltipText"
  [tooltipContainerListeners]="true"
  (tooltipClick)="tooltipClickEvent.emit()"
>
  <kp-svg
    [key]="isHeartFilled ? 'heartFilled' : 'heart'"
    size="14px"
    [ngClass]="{
      'change-img-color-black': isHeartFilled,
      'change-img-color': !isHeartFilled
    }"
  ></kp-svg>

  <p>{{ countOfLikes }} Нравится</p>
</div>
