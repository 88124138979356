import { Directive, ElementRef, HostListener, forwardRef, Inject, Renderer2 } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Directive({
  selector: '[kpContenteditable]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContenteditableValueAccessorDirective),
      multi: true,
    },
  ],
})
export class ContenteditableValueAccessorDirective implements ControlValueAccessor {
  private static processValue(value: string | null): string {
    const processed = value || '';

    return processed.trim() === '<br>' ? '' : processed;
  }

  constructor(@Inject(ElementRef) private readonly elementRef: ElementRef, @Inject(Renderer2) private readonly renderer: Renderer2) {}

  private onTouched = () => {};

  private onChange: (value: string) => void = () => {};

  registerOnChange(onChange: (value: string) => void) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  @HostListener('input')
  onInput() {
    this.onChange(this.elementRef.nativeElement.innerHTML);
  }

  @HostListener('blur')
  onBlur() {
    this.onTouched();
  }

  setDisabledState(disabled: boolean) {
    this.renderer.setAttribute(this.elementRef.nativeElement, 'contenteditable', String(!disabled));
  }

  // writeValue(value: string | null) {
  //   this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', value);
  // }

  writeValue(value: string | null) {
    this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', ContenteditableValueAccessorDirective.processValue(value));
  }
}
