<div class="container">
  <div class="default-container__header">
    <div class="container__header">Предстоящие мероприятия</div>

    <div class="name-value-grid__value">{{ upcomingEvents.length }}</div>
  </div>

  <div class="container__content">
    <div class="event-card" *ngFor="let incomeEvent of displayUpcomingEvents">
      <div class="date">
        <p>{{ incomeEvent.date?.format('D MMMM H:mm') }}</p>
      </div>

      <div class="title">{{ incomeEvent.title }}</div>

      <div class="address">{{ incomeEvent.address }}</div>
    </div>
  </div>
</div>
