import { Component, Input, OnInit } from '@angular/core';

interface IncomeEvent {
  date: moment.Moment;
  title: string;
  address: string;
}

@Component({
  selector: 'kp-upcoming-events',
  templateUrl: './upcoming-events.component.html',
  styleUrls: ['./upcoming-events.component.scss'],
})
export class UpcomingEventsComponent implements OnInit {
  @Input() upcomingEvents: IncomeEvent[] = [
    {
      date: null,
      title: 'Первый Всероссийский открытый конгресс в финансовом секторе.',
      address: 'г. Москва, ул. Ленина, 149, д. 4',
    },
    {
      date: null,
      title: 'Первый Всероссийский открытый конгресс в финансовом секторе.',
      address: 'г. Москва, ул. Ленина, 149, д. 4',
    },
    {
      date: null,
      title: 'Первый Всероссийский открытый конгресс в финансовом секторе.',
      address: 'г. Москва, ул. Ленина, 149, д. 4',
    },
    {
      date: null,
      title: 'Первый Всероссийский открытый конгресс в финансовом секторе.',
      address: 'г. Москва, ул. Ленина, 149, д. 4',
    },
  ];

  displayUpcomingEvents: IncomeEvent[];

  ngOnInit() {
    this.displayUpcomingEvents = this.upcomingEvents.slice(0, 3);
  }
}
