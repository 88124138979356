import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter, finalize, first, map, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { IUser, IUserAuth, IUserShort, IUserWithProfile } from '@shared/models/user/view/user.model';
import { AuthHttpService } from '@core/services/api/auth/auth-http.service';
import { KeyValue } from '@shared/models/key-value.model';
import {
  IUserAuthDto,
  IUserDto,
  IUserGroup,
  IUserShortDto,
  IUserWithProfileDto,
} from '@shared/models/user/dto/user-dto.model';
import { DateService } from '../utils/date.service';
import { AUTH_REFRESH_TOKEN_LS_KEY, AUTH_TOKEN_LS_KEY } from '@shared/constants/common.const';
import { IUserChild } from '@shared/models/user/view/user-child.model';
import { IUserChildDto } from '@shared/models/user/dto/user-child-dto.model';
import { IUserBusinessExperienceDto } from '@shared/models/user/dto/user-business-experience-dto.model';
import { IUserBusinessExperience } from '@shared/models/user/view/user-business-experience.model';
import { IUserEducationDto } from '@shared/models/user/dto/user-education-dto.model';
import { IUserEducation } from '@shared/models/user/view/user-education.model';
import { UserTypeKey } from '@shared/enums/keys.enum';

import {
  ACTIVE_MEMBER_MENU_OPTIONS_FOR_STAFF,
  INACTIVE_MEMBER_MENU_OPTIONS_FOR_STAFF,
  MEMBER_MENU_OPTIONS_FOR_MEMBER,
} from '@modules/members/constants/selects-options.const';
import { BaseClass } from '@shared/components/base/base.class';
import { ISelectOption } from '@shared/models/select.model';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpRequest } from '@angular/common/http';
import { RefreshTokenModel } from '@shared/models/admin/dto/refresh-token.model';
import { NotificationsPushService } from '../notifications/notifications-push.service';
import { ProfileUserRole } from '@modules/profile/enums/keys.enum';

const LAST_ACTIVITY_REQUEST_DATE_TIME_FORMAT: string = 'YYYY-MM-DD HH:mm:ssZ';
const LAST_ACTIVITY_REQUEST_POLLING_INTERVAL: number = 60_000;

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseClass {
  public readonly currentUser$$: BehaviorSubject<IUserAuth> = new BehaviorSubject<IUserAuth>({
    token: localStorage.getItem(AUTH_TOKEN_LS_KEY),
    user: null,
  });

  private readonly isRefreshTokenRequestInProcess$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly logoutTrigger$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private authHttpService: AuthHttpService,
    private dateService: DateService,
    private notificationsPushService: NotificationsPushService,
  ) {
    super();
    navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: '/firebase-cloud-messaging-push-scope' });
  }

  public setCurrentUserState(updatedUser: IUserAuth): void {
    this.currentUser$$.next(updatedUser);
  }

  authenticate(userData: KeyValue<string>): Observable<IUserAuth> {
    this._clearStorages();

    return this.authHttpService.authenticate(userData).pipe(
      map((userAuth: IUserAuthDto) => {
        const userAuthView = this._transformUserAuthDtoToUserAuthView(userAuth);

        if (userAuthView?.token) {
          localStorage.setItem(AUTH_TOKEN_LS_KEY, userAuthView.token);
          localStorage.setItem(AUTH_REFRESH_TOKEN_LS_KEY, userAuthView.refresh_token);

          this.setCurrentUserState(userAuthView);
        }

        return userAuthView;
      }),
    );
  }

  // Логин через смс
  authenticateByCodeNumber(userData: any): Observable<any> {
    this._clearStorages();

    return this.authHttpService.authenticationByCode(userData).pipe(
      map((userAuth: any) => {
        const userAuthView = this._transformUserAuthDtoToUserAuthView(userAuth);

        if (userAuthView?.token) {
          localStorage.setItem(AUTH_TOKEN_LS_KEY, userAuthView.token);
          localStorage.setItem(AUTH_REFRESH_TOKEN_LS_KEY, userAuthView.refresh_token);

          this.setCurrentUserState(userAuthView);
        }

        return userAuthView;
      }),
    );
  }

  public refreshTokenAndRepeatRequest(request: HttpRequest<unknown>, httpEventsStream: HttpHandler): Observable<any> {
    return this.isRefreshTokenRequestInProcess$.pipe(
      /**
       * Необходимо дождаться выполнения предыдущего рефреша перед выполнением следующего, чтобы не возникло состояния гонки
       */
      first((isRequestInProcess: boolean) => !isRequestInProcess),
      take(1),
      switchMap(() => {
        this.isRefreshTokenRequestInProcess$.next(true);

        return this.authHttpService.refreshToken();
      }),
      catchError((error: HttpErrorResponse) => {
        // Если нет доступа к refreshToken, значит текущий токен пользователя невалидный/сильно устарел
        if (error.status === 401) {
          this.logoutBySystem();
        } else {
          return throwError(error);
        }
      }),
      switchMap((updatedTokens: RefreshTokenModel) =>
        this.replaceTokenAfterRefresh(updatedTokens, request, httpEventsStream),
      ),
    );
  }

  public getUser(): Observable<IUser> {
    return this.authHttpService.getUser().pipe(
      filter((user: IUserDto) => !!user),
      map((userDto: IUserDto) => this._transformUserDtoToUserView(userDto)),
    );
  }

  public logoutByUser(): void {
    this.notificationsPushService
      .deletePushOfDeviceUser()
      .pipe(finalize(() => this.logoutBySystem()))
      .subscribe();
  }

  public logoutBySystem(): void {
    this._clearStorages();
    this.setCurrentUserState(null);
    this.logoutTrigger$.next();
    this.router.navigate(['/login']);
  }

  public replaceTokenAfterRefresh(
    updatedTokens: RefreshTokenModel,
    request: HttpRequest<unknown>,
    httpEventsStream: HttpHandler,
  ): Observable<HttpEvent<unknown> | boolean> {
    return this.currentUser$$.pipe(
      take(1),
      switchMap((currentUser: IUserAuth) => {
        const authToken: string = updatedTokens.access;
        const updatedUser: IUserAuth = { ...currentUser, token: authToken };
        this.setCurrentUserState(updatedUser);

        localStorage.setItem(AUTH_TOKEN_LS_KEY, authToken);
        localStorage.setItem(AUTH_REFRESH_TOKEN_LS_KEY, updatedTokens.refresh);

        const requestWithNewToken: HttpRequest<unknown> = request.clone({
          headers: new HttpHeaders({
            Authorization: `Bearer ${authToken}`,
          }),
        });

        this.isRefreshTokenRequestInProcess$.next(false);

        return httpEventsStream.handle(requestWithNewToken) ?? of(true);
      }),
    );
  }

  // Удалить логику после проверки на деве нового сокета участников
  // public startUserActivityConstantPolling(): void {
  //   const lastActivityRequestDatetimeStr: string = localStorage.getItem(LAST_UPDATE_ACTIVITY_REQUEST_DATE_TIME_LOCAL);
  //   if (lastActivityRequestDatetimeStr) {
  //     const secondsSpentAfterLastActivityRequest: number = moment().diff(
  //       moment(lastActivityRequestDatetimeStr, LAST_ACTIVITY_REQUEST_DATE_TIME_FORMAT),
  //       'minutes',
  //     );
  //
  //     if (secondsSpentAfterLastActivityRequest >= LAST_ACTIVITY_REQUEST_POLLING_INTERVAL) {
  //       this._updateUserActivityTime().subscribe();
  //     }
  //   } else {
  //     this._updateUserActivityTime().subscribe();
  //   }
  //
  //   // Прерываем предыдущие возможные потоки
  //   this.logoutTrigger$.next();
  //
  //   interval(LAST_ACTIVITY_REQUEST_POLLING_INTERVAL)
  //     .pipe(
  //       takeUntil(this.logoutTrigger$),
  //       switchMap(() => this._updateUserActivityTime()),
  //     )
  //     .subscribe();
  // }
  //
  // // Запрос на проверку активен ли пользователь
  // private _updateUserActivityTime(): Observable<void> {
  //   return this.currentUser$$.pipe(
  //     take(1),
  //     filter((currentUser: IUserAuth) => !!currentUser?.user),
  //     switchMap((currentUser: IUserAuth) => this.authHttpService.updateUserActivityTime(currentUser.user.id)),
  //     tap(() =>
  //       localStorage.setItem(
  //         LAST_UPDATE_ACTIVITY_REQUEST_DATE_TIME_LOCAL,
  //         moment().format(LAST_ACTIVITY_REQUEST_DATE_TIME_FORMAT),
  //       ),
  //     ),
  //   );
  // }

  private _clearStorages() {
    localStorage.clear();
    sessionStorage.clear();
  }

  get currentUser(): IUser {
    return this.currentUser$$?.value?.user;
  }

  get currentUserDto(): IUserDto {
    return this.currentUser$$?.value?.user;
  }

  get isAuthenticated(): boolean {
    return !!this.currentUser$$?.value?.token;
  }

  private _transformUserAuthDtoToUserAuthView(userAuthDto: IUserAuthDto): IUserAuth {
    let userAuthView: Partial<IUserAuth> = {};
    userAuthView.user = this._transformUserDtoToUserView(userAuthDto.user);

    userAuthView = Object.assign(userAuthDto, userAuthView);
    return userAuthView as IUserAuth;
  }

  private _transformUserDtoToUserView(userDto: IUserDto): IUser {
    let userView: Partial<IUser> = {};

    userView.permissions = userDto.groups[0]?.permissions;
    userView.isStaff = this._isUserTypeStaff(userDto.user_type);
    userView.isMember = this._isUserTypeMember(userDto.user_type);
    userView.isGuest = this._isUserTypeGuest(userDto.user_type);

    userView.profilePageRoute = this._getProfileRouteById(userDto.id);
    userView.settingsPageRoute = this._getSettingsRouteById(userDto.id);
    userView.editPageRoute = this._getEditRouteById(userDto.id);

    userView.avatar = userDto.avatar ? userDto.avatar : 'assets/images/temp/user-profile-placeholder.png';

    userView = Object.assign(userDto, userView);
    return userView as IUser;
  }

  transformUserShortDtoToUserShortView(userDto: IUserShortDto): IUserShort {
    if (!userDto) {
      return;
    }

    let userView: Partial<IUserShort> = {};

    userView.firstAndLastName = this._getFirstAndLastName(userDto.last_name, userDto.first_name);

    userView.profilePageRoute = this._getProfileRouteById(userDto.id);
    userView.settingsPageRoute = this._getSettingsRouteById(userDto.id);
    userView.editPageRoute = this._getEditRouteById(userDto.id);

    userView.avatar = userDto.avatar ? userDto.avatar : 'assets/images/temp/user-placeholder.png';

    userView = Object.assign(userDto, userView);
    return userView as IUserShort;
  }

  transformUserWithProfileDtoToUserWithProfileView(
    userDto: IUserWithProfileDto,
    setDefaultAvatar: boolean = true,
  ): IUserWithProfile {
    let userView: Partial<IUserWithProfile> = {};

    userView.admin = this.transformUserShortDtoToUserShortView(userDto.admin);
    userView.curator = this.transformUserShortDtoToUserShortView(userDto.curator);

    if (setDefaultAvatar) {
      userView.avatar = userDto.avatar ? userDto.avatar : 'assets/images/temp/user-profile-placeholder.png';
    }

    userView.date_of_birthday = this.dateService.transfromStringToMoment(userDto.date_of_birthday);
    userView.firstAndLastName = this._getFirstAndLastName(userDto.last_name, userDto.first_name);

    userView.childrens = this._transformChildrenDtosToViews(userDto.childrens);
    userView.childrenInArrayShortString = this._transformChildrenViewsToArrayShortString(userView.childrens);

    userView.business_experience = this._transformBusinessExperienceDtosToViews(userDto.business_experience);
    userView.businessExperienceInArrayString = this._transformBusinessExperienceViewsToArrayString(
      userView.business_experience,
    );

    userView.education = this._transformEducationDtosToViews(userDto.education);
    userView.educationInArrayString = this._transformEducationViewsToArrayString(userView.education);

    userView.menuOptions = this.getMemberMenuOptions(userDto.is_active);

    userView.profilePageRoute = this._getProfileRouteById(userDto.id);
    userView.settingsPageRoute = this._getSettingsRouteById(userDto.id);
    userView.editPageRoute = this._getEditRouteById(userDto.id);

    userView.statusInClubInArrayString = userDto.status_in_club ? userDto.status_in_club.map((x) => x.name) : [];
    userView.statusInClubInArrayStringWithHashSymbol = userView.statusInClubInArrayString
      ? userView.statusInClubInArrayString.map((statusName) => `#${statusName}`)
      : [];

    userView.additionalIndustriesInArrayString = userDto.additional_industries
      ? userDto.additional_industries.map((x) => x.name)
      : [];
    userView.additionalIndustriesInString = userView.additionalIndustriesInArrayString.join(', ');

    userView.primaryIndustriesInArrayString = userDto.primary_industries
      ? userDto.primary_industries.map((x) => x.name)
      : [];
    userView.primaryIndustriesInString = userView.primaryIndustriesInArrayString.join(', ');

    userView.allIndustriesInArrayString = [
      ...userView.primaryIndustriesInArrayString,
      ...userView.additionalIndustriesInArrayString,
    ];
    userView.allIndustriesInString = userView.allIndustriesInArrayString.join(', ');

    userView.profilePesonalFullness = this.calculateProfilePersonalFieldsWeight(userDto);

    userView = Object.assign(userDto, userView);

    return userView as IUserWithProfile;
  }

  // Считает, процент заполненности раздела "личное" в редактировании профиля
  calculateProfilePersonalFieldsWeight<T>(obj: T): string {
    let totalWeight = 0;
    const defaultFieldWeight = 2;
    const reducedFieldWeight = 1;

    const fieldsToCheck = [
      'date_of_birthday',
      'marital_status',
      'hobbies',
      'native_city',
      'instagram_link',
      'facebook_link',
    ];

    const fieldsWithWeightOne: any = ['instagram_link', 'facebook_link']; // У этих полей вес 1

    fieldsToCheck.forEach((field) => {
      const fieldValue = obj[field as keyof typeof obj];

      if (
        obj[field as keyof typeof obj] !== undefined &&
        obj[field as keyof typeof obj] !== null &&
        !(Array.isArray(fieldValue) && fieldValue.length === 0) &&
        !(typeof fieldValue === 'string' && fieldValue.trim() === '')
      ) {
        totalWeight += fieldsWithWeightOne.includes(field) ? reducedFieldWeight : defaultFieldWeight;
      }
    });

    const countProf = 10 - totalWeight;
    return countProf === 0 ? null : `${countProf}%`;
  }

  getMemberMenuOptions(isActivated: boolean): ISelectOption[] {
    if (this.currentUser?.isStaff) {
      return isActivated ? ACTIVE_MEMBER_MENU_OPTIONS_FOR_STAFF : INACTIVE_MEMBER_MENU_OPTIONS_FOR_STAFF;
    }
    if (this.currentUser?.isMember) {
      return MEMBER_MENU_OPTIONS_FOR_MEMBER;
    }
  }

  private _transformChildrenDtosToViews(dtos: IUserChildDto[]): IUserChild[] {
    if (!dtos?.length) {
      return [];
    }

    return dtos.map((dto) => {
      const view = dto as unknown as IUserChild;
      view.date_of_birthday = this.dateService.transfromStringToMoment(dto.date_of_birthday);
      return view;
    });
  }

  private _transformChildrenViewsToArrayShortString(views: IUserChild[]): string[] {
    if (!views?.length) {
      return ['Нет'];
    }

    return views.map((view) => `${this.sexKeyToViewNameShort[view.sex]}-${view.date_of_birthday?.format('YYYY')}`);
  }

  private _transformBusinessExperienceDtosToViews(dtos: IUserBusinessExperienceDto[]): IUserBusinessExperience[] {
    if (!dtos?.length) {
      return [];
    }

    return dtos.map((dto) => {
      const view = dto as unknown as IUserBusinessExperience;
      // view.start_experience = this.dateService.transfromStringToMoment(dto.start_experience);
      // view.end_experience = this.dateService.transfromStringToMoment(dto.end_experience);
      return view;
    });
  }

  private _transformBusinessExperienceViewsToArrayString(views: IUserBusinessExperience[]): string[] {
    if (!views?.length) {
      return [];
    }

    // return views.map(
    //   (view) =>
    //     `${view.company}, ${view.city}. ${view.position}, ${view.start_experience?.format(
    //       'MM/YYYY',
    //     )} — ${view.end_experience?.format('MM/YYYY')}`,
    // );
  }

  private _transformEducationDtosToViews(dtos: IUserEducationDto[]): IUserEducation[] {
    if (!dtos?.length) {
      return [];
    }

    return dtos.map((dto) => {
      const view = dto as unknown as IUserEducation;
      // view.start_education = this.dateService.transfromStringToMoment(dto.start_education);
      // view.end_education = this.dateService.transfromStringToMoment(dto.end_education);
      return view;
    });
  }

  private _transformEducationViewsToArrayString(views: IUserEducation[]): string[] {
    if (!views?.length) {
      return [];
    }

    // return views.map(
    //   (view) =>
    //     `${view.educational_institution}. ${view.faculty}. ${
    //       view.start_education ? view.start_education.format('YYYY') : ''
    //     } — ${view.end_education ? view.end_education.format('YYYY') : ''}. ${view.specialization}`,
    // );
  }

  private _getProfileRouteById(id: number) {
    return `/profile/${id}`;
  }

  private _getSettingsRouteById(id: number) {
    return `/profile/${id}/settings`;
  }

  private _getEditRouteById(id: number) {
    return `/profile/${id}/edit`;
  }

  private _getFirstAndLastName(first: string, last: string) {
    if (first && last) {
      return `${first} ${last}`;
    }
    if (first) {
      return first;
    }
    if (last) {
      return last;
    }
    return '';
  }

  private _isUserTypeStaff(userType: number): boolean {
    return userType.toString() === UserTypeKey.Staff;
  }

  private _isUserTypeMember(userType: number): boolean {
    return userType.toString() === UserTypeKey.Member;
  }

  private _isUserTypeGuest(userType: number): boolean {
    return userType.toString() === UserTypeKey.Guest;
  }

  get isUserAdmin() {
    return this.currentUser$$.value.user?.groups?.some((el: IUserGroup): boolean => el.id === ProfileUserRole.Admin);
  }

  get isUserCurator() {
    return this.currentUser$$.value.user?.groups?.some((el: IUserGroup): boolean => el.id === ProfileUserRole.Curator);
  }
}
