<div
  class="avatar-container"
  [style.height.px]="avatarSize"
  [style.width.px]="avatarSize"
  [style.font-size.px]="fontSize"
  [style.color]="fontColor"
  [style.background-color]="backgroundColor"
>
  <ng-container *ngIf="avatarUrl$ | async; then imageAvatar; else userInitials"></ng-container>

  <ng-content></ng-content>
</div>

<ng-template #imageAvatar>
  <img
    [src]="avatarUrl$ | async"
    (load)="avatarLoaded$.next(true)"
    [style.display]="(avatarLoaded$ | async) ? 'block' : 'none'"
    alt=""
    fxFill
  >

  <mat-spinner
    *ngIf="!(avatarLoaded$ | async)"
    [diameter]="fontSize < minimumDiameter ? minimumDiameter : fontSize" [strokeWidth]="2"
  ></mat-spinner>
</ng-template>

<ng-template #userInitials>
  <span>
    {{ userInitials$ | async }}
  </span>
</ng-template>
