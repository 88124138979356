import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DAY_NUMBER_AND_MONTH_WORD, MASK_HOUR_MINUTE } from '@shared/constants/common.const';

/**
 * Получение относительной даты/времени, если дата сегодняшняя - вернуть только время, или - число + месяц
 */
@Pipe({
  name: 'datetimeRelativeToday',
  pure: true,
})
export class DatetimeRelativeTodayPipe implements PipeTransform {
  constructor() {}

  transform(date: string | moment.Moment) {
    if (!date) {
      return;
    }

    const dateAsMoment: moment.Moment = moment(date);

    return dateAsMoment.format(dateAsMoment.diff(moment(), 'days') ? DAY_NUMBER_AND_MONTH_WORD : MASK_HOUR_MINUTE);
  }
}
