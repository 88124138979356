export enum SexKey {
  Female = '0',
  Male = '1',
}

export enum FormOfParticipationKey {
  Offline = '0',
  Online = '1',
}

export enum MaritalStatusKey {
  Married = '0',
  Single = '1',
}

export enum NotificationTypeEnum {
  // Все
  DEFAULT = 0,
  // Участники
  MEMBERS = 1,
  // Посты
  POSTS = 2,
  // Форумы
  FORUMS = 3,
  // Мероприятия
  EVENTS = 4,
  //Встречи
  MEETINGS = 5,
  // Чаты
  CHATS = 6,
  // Оплата
  PAYMENT = 7,
  // Благодарность
  GRATITUDE = 8,
}

export enum ProfileUserRole {
  Admin = 1,
  Curator= 3,
}

export enum ProfilePersonalInfoTypeOfAgreementsEnum {
  Transfer,
  Distribution,
  UseOfImage,
}
