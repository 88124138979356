import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { AuthCanLoadGuard } from '@core/guards/auth.can-load.guard';
import { CustomRouteReuseStrategy } from '@core/router-strategy/router-strategy';
import {
  RedirectToStorePageComponent,
} from '@shared/components/redirect-to-store-page/redirect-to-store-page.component';
import { TermsOfUseComponent } from '@shared/components/termsOfUse/terms-of-use.component';

const publicRoutes: Routes = [
  {
    path: 'terms-of-use',
    component: TermsOfUseComponent,
  },
  {
    path: 'define-user-agent',
    component: RedirectToStorePageComponent,
  },
];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'messages',
    pathMatch: 'full',
  },

  ...publicRoutes,

  {
    path: '',
    loadChildren: () => import('./modules/public/public.module').then((m) => m.PublicModule),
  },
  {
    path: '',
    loadChildren: () => import('./modules/private/private.module').then((m) => m.PrivateModule),
    canLoad: [AuthCanLoadGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
  ]
})
export class AppRoutingModule {}
