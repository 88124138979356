import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'kp-comments-switcher',
  templateUrl: './comments-switcher.component.html',
  styleUrls: ['./comments-switcher.component.scss'],
})
export class CommentsSwitcherComponent {
  @Input() countOfComments: number;
  @Output() clickEvent = new EventEmitter();
}
