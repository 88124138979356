import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'kp-likes',
  templateUrl: './likes.component.html',
  styleUrls: ['./likes.component.scss'],
})
export class LikesComponent {
  @Input() countOfLikes: number;
  @Input() isHeartFilled: boolean;
  @Input() tooltipText: string;
  @Output() clickEvent = new EventEmitter();
  @Output() tooltipClickEvent = new EventEmitter();
  @Output() hoverEvent = new EventEmitter();
}
